import React from 'react';
import {View, Image} from 'react-native';
import Text from '@/components/basic/text';
import theme from '@/style';
import {toPriceStr} from '@/components/utils';
import globalStore from '@/services/global.state';
import LazyImage from '@/components/basic/image';
import {useInnerStyle} from './recharge.hooks';
import DarkRefresh from '../svg/dark-refresh';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {useTranslation} from 'react-i18next';
const rightIcon = require('@components/assets/icons/chevron-right.png');
export interface RechargeBalanceProps {
  balance?: number;
  payMethod?: string;
  tip?: string;
  onRefresh?: () => void;
  onGotoRecords?: () => void;
}

const RechargeBalance: React.FC<RechargeBalanceProps> = props => {
  const {i18n} = useTranslation();
  const {
    balance = 0,
    payMethod = '',
    onRefresh = () => {},
    onGotoRecords,
    tip = i18n.t('recharge-page.tip.payMethodTip'),
  } = props;
  const {
    size: {rightIconSize},
    amountStyle,
    balanceStyles: styles,
  } = useInnerStyle();
  return (
    <View style={[theme.margin.topl, styles.bgBox, styles.container]}>
      <Image
        source={require('@assets/imgs/recharge-header-bg.webp')}
        style={[styles.cardBg, styles.bgBox]}
      />
      <View style={[theme.flex.between, theme.flex.flex1]}>
        <View
          style={[styles.balanceContainer, theme.flex.row, theme.flex.between]}>
          <View style={[theme.flex.flex1]}>
            <Text color={theme.basicColor.white} style={[amountStyle.opacity]}>
              {i18n.t('label.balance')}
            </Text>
            <View style={[theme.flex.row, theme.flex.alignEnd]}>
              <Text
                fontFamily="fontInter"
                blod
                fontSize={24}
                style={[theme.font.white]}>
                {toPriceStr(balance, {
                  thousands: true,
                  spacing: true,
                  currency: globalStore.currency,
                })}
              </Text>
              <NativeTouchableOpacity
                activeOpacity={0.8}
                onPress={onRefresh}
                style={[theme.margin.leftm]}>
                <DarkRefresh />
              </NativeTouchableOpacity>
            </View>
          </View>
          <NativeTouchableOpacity
            activeOpacity={0.8}
            style={[theme.flex.row, theme.flex.centerByCol]}
            onPress={onGotoRecords}>
            <View style={[theme.flex.alignEnd, theme.margin.rights]}>
              <Text color={theme.basicColor.white}>
                {i18n.t('label.recharge')}
              </Text>
              <Text color={theme.basicColor.white}>
                {i18n.t('recharge-page.label.records')}
              </Text>
            </View>
            <LazyImage
              occupancy={'transparent'}
              imageUrl={rightIcon}
              width={rightIconSize}
              height={rightIconSize}
            />
          </NativeTouchableOpacity>
        </View>
        <View style={[styles.methodContainer, theme.padding.tbs]}>
          <Text color={theme.basicColor.white}>
            {i18n.t('recharge-page.currentMethod')}:
            <Text color={theme.basicColor.white} style={[theme.margin.leftxxs]}>
              {payMethod}
            </Text>
          </Text>
          <View style={[theme.margin.topxxs]}>
            <Text color={theme.basicColor.white} style={[amountStyle.opacity]}>
              {tip}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default RechargeBalance;
