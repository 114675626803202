import React from 'react';
import Text from '@basicComponents/text';
import {View} from 'react-native';
import theme from '@style';
import {PayMethod} from './recharge.service';
import LazyImage from '@/components/basic/image';
import {useInnerStyle} from './recharge.hooks';
import Ok from '../svg/ok';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {useTranslation} from 'react-i18next';

export interface RechargeChannelProps {
  payMethodList: PayMethod[];
  payMethodId?: number;
  onPayMethodChange: (id: number) => void;
}

const RechargeChannel: React.FC<RechargeChannelProps> = ({
  payMethodList,
  payMethodId,
  onPayMethodChange,
}) => {
  const {i18n} = useTranslation();
  const {payMethodStyles} = useInnerStyle();
  return (
    <View
      style={[
        theme.flex.col,
        theme.borderRadius.m,
        theme.background.white,
        theme.padding.l,
        theme.margin.topl,
      ]}>
      <Text fontSize={theme.fontSize.m} main>
        {i18n.t('recharge-page.label.channel')}
      </Text>
      <View style={[theme.flex.row, theme.flex.wrap, theme.margin.tops]}>
        {payMethodList.map((payMethod, index) => {
          return (
            <NativeTouchableOpacity
              key={index}
              onPress={() => onPayMethodChange(payMethod.id)}>
              <View
                key={index}
                style={[
                  payMethodStyles.item,
                  theme.flex.col,
                  theme.flex.around,
                  theme.position.rel,
                  theme.borderRadius.xs,
                  index % 3 !== 0 ? theme.margin.lefts : null,
                  index > 2 ? theme.margin.tops : null,
                  payMethod.id === payMethodId
                    ? payMethodStyles.itemSelected
                    : null,
                ]}>
                {payMethod.id === payMethodId && (
                  <View
                    style={[
                      theme.position.abs,
                      payMethodStyles.itemSelectedIcon,
                      theme.flex.center,
                    ]}>
                    <Ok />
                  </View>
                )}
                <View style={[theme.flex.center]}>
                  <LazyImage
                    occupancy="#0000"
                    imageUrl={payMethod.payIcon}
                    height={theme.iconSize.xxl}
                  />
                </View>
                <Text
                  fontSize={theme.fontSize.s}
                  second
                  numberOfLines={2}
                  ellipsizeMode="tail"
                  style={[
                    payMethodStyles.itemText,
                    theme.font.center,
                    {
                      lineHeight: theme.fontSize.l,
                    },
                  ]}>
                  {payMethod.payName}
                </Text>
              </View>
            </NativeTouchableOpacity>
          );
        })}
      </View>
    </View>
  );
};

export default RechargeChannel;
